import { sharedTypes } from '.';
import { ReepayAgreement } from '../reepay';

/**
 * These values are defined in Umbraco, as the
 * `Shared: Dropdown - Membership areas` data type.
 */
export enum ReepayGtmAreas {
    Plus = 'Plus',
    Starplus = 'Starplus',
}

export type ReepayFormContent = {
    benefitAgreements: ReepayAgreement[];
    receiptPage: {
        url: string;
        name: string;
    };
    gtmTrackAs: ReepayGtmAreas;
    goBackText?: string;
    formHeader: string;
    formSubheader: string;
    informationHeader: string;
    paymentPlanHeader: string;
    goToPaymentButtonText: string;
    emailLabel: string;
    emailPlaceholder: string;
    emailValidationMessage: string;
    phoneLabel: string;
    phonePlaceholder: string;
    phoneValidationMessage: string;
    companyLabel: string;
    companyPlaceholder: string;
    nameLabel: string;
    namePlaceholder: string;
    nameValidationMessage: string;
    addressLabel: string;
    addressPlaceholder: string;
    addressValidationMessage: string;
    zipcodeLabel: string;
    zipcodePlaceholder: string;
    zipcodeValidationMessage: string;
    cityLabel: string;
    cityPlaceholder: string;
    cityValidationMessage: string;
    showVoucherInputText: string;
    hideVoucherInputText: string;
    voucherCodeLabel: string;
    voucherCodePlaceholder: string;
    invalidVoucherMessage: string;
    gdprPolicyLabel: string;
    gdprTermsLinkText: string;
    gdprPolicyError: string;
    privacyPolicyUrl: sharedTypes.CtaUrl;
    consentModalHeader: string;
    consentModalButtonText: string;
    marketingTerms: string;
    marketingPermissionLabel: string;
    marketingTermsLinkText: string;
    marketingPermissionValidationMessage: string;
    consentCode: number;
    errorMessages: ReepayApiErrorMessage[];
};

export type ReepayApiErrorMessage = {
    apiErrorCode: string;
    errorMessageInFrontend: string;
};
