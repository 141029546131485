import { ReepayConsent, ReepayCustomer } from './customer.types';

export type ReepayPlanResponse = {
    plans: {
        handle: string;
        amount: number;
    }[];
};

/**
 * These values are defined in Umbraco, as the
 * `Shared: Dropdown - Payment plan recurrence` data type.
 */
export enum ReepayPlanRecurrence {
    Monthly = 'Månedlig',
    Yearly = 'Årlig',
}

export type ReepayPlan = {
    planId: string;
    amount: number;
    label: string;
    savingsText: string;
    gtmTrackAs: ReepayPlanRecurrence;
};

export type ReepayPlanRequestParams = {
    plans: {
        key: string;
        value: string;
    }[];
};

export type ReepayAgreement = {
    reepayAccountId: string;
    agreementName: string;
    paymentPlans: ReepayPlan[];
    agreementID: string;
};

export type CreateSessionRequest = {
    reepayAccountType: string;
    planHandle: string;
    voucher: string;
    customer: ReepayCustomer;
    consentDetails: ReepayConsent;
};

export type SessionRequestResponse = {
    id: string;
};

export type ReepaySubscriptionResponse = {
    id: string;
    invoice: string;
    customer: string;
    subscription: string;
    payment_method: string;
    error: string;
};
